import { useWeb3React } from '@web3-react/core'
import LogoMainnet from 'assets/images/xdc-logo.png'
import LogoTestnet from 'assets/images/xdc-logo-test.png'
import { SupportedChainId } from 'constants/chains'
import { Chain, TokenBalance } from 'graphql/data/__generated__/types-and-hooks'
import { CHAIN_ID_TO_BACKEND_NAME } from 'graphql/data/util'
import { useEffect, useMemo, useState } from 'react'
import { formatCurrencyAmount, getBalanceAmount, getDecimalAmount } from 'utils/formatCurrencyAmount'
import { getPercentChange } from 'utils/thegraph'

import { useNativeCurrencyBalances } from '../state/connection/hooks'
import { useEthPrices } from './useEthPrices'

const baseListTokenUrls: {
  [key: number]: string
} = {
  50: 'https://xdcscan.com/api/tokens/holding/xrc20/',
  51: 'https://explorer.apothem.network/api/tokens/holding/xrc20/',
}

export default function usePortfoliosListTokens(walletAddress: string) {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [tokenBalances, setTokenBalances] = useState<TokenBalance[]>([])
  const [totalCurrentBalance, setTotalCurrentBalance] = useState<number>(0)
  const [totalBalanceChange, setTotalBalanceChange] = useState<number>(0)
  const [totalPercentChange, setTotalPercentChange] = useState<number>(0)

  const { chainId } = useWeb3React()
  const xdcPrice = useEthPrices()

  const walletBalances = useNativeCurrencyBalances([walletAddress])

  const nativeBalance = Number(formatCurrencyAmount(walletBalances[walletAddress], 4).replace(',', ''))

  const baseUrl = useMemo(() => {
    return baseListTokenUrls[chainId ?? SupportedChainId.XDC]
  }, [chainId])

  const apiUrl = `${baseUrl}${walletAddress}?page=1&limit=50`

  useEffect(() => {
    const fetchTokenData = async () => {
      try {
        setIsLoading(true)
        const response = await fetch(apiUrl)
        const data = await response.json()
        const nativeToken = {
          createdAt: '2023-04-06T11:09:46.247Z',
          hash: 'xdcc12eb574621c0d0d3dabce0ef50de395c3695b6a',
          quantity: getDecimalAmount(nativeBalance, 18),
          quantityNumber: getDecimalAmount(nativeBalance, 18),
          token: 'xdcdf5038d080ca5bd21c6c90f0b004306a26af4ba8',
          tokenObj: {
            logoUrl: chainId === SupportedChainId.XDC_TESTNET ? LogoTestnet : LogoMainnet,
            changePercent: getPercentChange(xdcPrice?.current.toString(), xdcPrice?.oneDay.toString()),
            circulatingSupply: '0',
            circulatingSupplyNumber: 0,
            coingeckoID: '0',
            createdAt: '2023-04-04T10:03:20.037Z',
            decimals: 18,
            hash: '',
            holderCount: 24,
            id: '642bf5e828802b70c42b8cdf',
            isMintable: true,
            martketCap: 0,
            name: 'XDC',
            priceUSD: xdcPrice?.current ?? 0,
            status: true,
            symbol: 'XDC',
            totalSupply: '100000000000000000000000000000000000000000000000000000000000000000000000000000',
            totalSupplyNumber: 1e59,
            txCount: 29,
            type: 'xrc20',
            updatedAt: '2023-04-12T07:34:12.310Z',
            volume24h: 0,
            _id: '642bf5e828802b70c42b8cdf',
          },
          updatedAt: '2023-04-08T04:50:07.600Z',
          _id: '642ea87a28802b70c46692f8',
        }

        data.items.unshift(nativeToken)
        const tokenBalances = data?.items?.map((item: any) => {
          return {
            __typename: 'TokenBalance',
            id: item.tokenObj._id,
            quantity: item.quantity,
            denominatedValue: {
              __typename: 'Amount',
              id: item.tokenObj.hash,
              currency: item.tokenObj.currency,
              value: Number(getBalanceAmount(item.quantity, item.tokenObj.decimals)) * item.tokenObj.priceUSD,
            },
            tokenProjectMarket: {
              __typename: 'TokenProjectMarket',
              id: item.tokenObj.hash,
              pricePercentChange: {
                __typename: 'Amount',
                id: item.tokenObj.hash,
                value: item.tokenObj.changePercent,
              },
              tokenProject: {
                __typename: 'TokenProject',
                id: item.tokenObj.hash,
                logoUrl: item.tokenObj.logoUrl,
              },
            },
            token: {
              __typename: 'Token',
              id: item.tokenObj._id,
              chain: chainId ? CHAIN_ID_TO_BACKEND_NAME[chainId] : Chain.Xinfin,
              address: item.tokenObj.hash.replace('xdc', '0x'),
              name: item.tokenObj.name,
              symbol: item.tokenObj.symbol,
              standard: item.tokenObj.type,
              decimals: item.tokenObj.decimals,
            },
          }
        })

        // calculate price
        const totalCurrentBalance =
          tokenBalances?.reduce((total: any, item: any) => {
            const denominatedValue = item?.denominatedValue?.value || 0
            return total + denominatedValue
          }, 0) || 0

        // nagative or positive
        const totalBalanceChange =
          tokenBalances?.reduce((total: any, item: any) => {
            const dominatedValue = item?.denominatedValue?.value || 0
            const pricePercentChange = item?.tokenProjectMarket?.pricePercentChange?.value || 0
            const valueBeforeChange = dominatedValue / (1 + pricePercentChange / 100)
            return total + dominatedValue - valueBeforeChange
          }, 0) || 0

        const totalValueBeforeChange = totalCurrentBalance - totalBalanceChange

        // nagative or positive
        const totalPercentChange =
          totalValueBeforeChange === 0 ? 0 : (totalBalanceChange / totalValueBeforeChange) * 100

        setTokenBalances(tokenBalances)
        setTotalCurrentBalance(totalCurrentBalance)
        setTotalBalanceChange(totalBalanceChange)
        setTotalPercentChange(totalPercentChange)
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
        console.error(error)
      }
    }
    if (!isLoading && xdcPrice && !isNaN(nativeBalance)) {
      fetchTokenData()
    }
  }, [chainId, walletAddress, xdcPrice, nativeBalance])

  return { isLoading, tokenBalances, totalCurrentBalance, totalBalanceChange, totalPercentChange }
}
